var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('main',{attrs:{"id":"principal"}},[_c('div',{staticClass:"contenedor-portada"},[_c('video',{staticClass:"portada",attrs:{"autoplay":"","muted":"","loop":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":`${_setup.cdn_archivos_dominio}/portada/Toxicologia-${
          _setup.esMovil ? 'movil' : 'escritorio'
        }.webm`,"type":"video/webm"}})]),_vm._m(0)]),_vm._m(1),_c('div',{staticClass:"contenedor ancho-fijo m-y-10"},[_c('div',{staticClass:"flex flex-contenido-separado contenedor ancho-fijo flex-columnas-100-al-simplificar"},[_c('div',{staticClass:"columna-16 m-b-3"},[_c('a',{staticClass:"tarjeta-hipervinculo-externo tarjeta-eni",attrs:{"href":`${_setup.dominio}/resa-atoyac/`,"target":"_blank","rel":"noopener noreferrer"}},[_c('picture',{staticClass:"fondo-imagen"},[_c('img',{attrs:{"src":`${_setup.cdn_archivos_dominio}/tarjetas/atoyac.toxi.png`}})]),_vm._m(2)])]),_c('div',{staticClass:"columna-16 m-b-3"},[_c('a',{staticClass:"tarjeta-hipervinculo-externo",attrs:{"href":`${_setup.url_gema}/visualizador?seccion=agentes-toxicos-y-procesos-contaminantes`,"target":"_blank"}},[_c('picture',{staticClass:"fondo-imagen"},[_c('img',{attrs:{"src":`${_setup.cdn_archivos_dominio}/tarjetas/eni-agentestoxicos-gema.png`}})]),_vm._m(3)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"degradado-negro-abajo"},[_c('div',{staticClass:"contenido-abajo"},[_c('div',{staticClass:"contenedor ancho-fijo"},[_c('h1',{staticClass:"titulo-eni texto-centrado texto-color-1"},[_vm._v(" Agentes Tóxicos y Procesos Contaminantes ")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"contenedor ancho-lectura m-y-10"},[_c('h2',{staticClass:"texto-centrado m-y-8-esc m-y-4-mov m-x-auto"},[_vm._v(" Ecosistema Nacional Informático de Agentes Tóxicos y Procesos Contaminantes ")]),_c('p',{staticClass:"texto-alto m-y-8-esc m-y-4-mov"},[_vm._v(" El Ecosistema Nacional Informático de Agentes Tóxicos y Procesos Contaminantes ofrece un espacio de acceso abierto para el análisis de datos y visualización de información generada en el marco del Programa Nacional Estratégico de Agentes Tóxicos y Procesos Contaminantes. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"degradado-negro-abajo"},[_c('div',{staticClass:"contenido-abajo"},[_c('p',{staticClass:"titulo-pagina texto-color-1"},[_vm._v(" Región de Emergencia Sanitaria y Ambiental: Cuenca del Alto Atoyac ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"degradado-negro-abajo"},[_c('div',{staticClass:"contenido-abajo"},[_c('p',{staticClass:"titulo-pagina texto-color-1"},[_vm._v(" Explora capas del tema agentes tóxicos y procesos contaminantes ")]),_c('hr'),_c('p',{staticClass:"subtitulo-capitulo texto-color-1"},[_vm._v(" Gestor de mapas (Gema) ")])])])
}]

export { render, staticRenderFns }