<script setup>
import { onBeforeUnmount, onMounted, computed } from 'vue'

const cdn_archivos_dominio = process.env.VUE_APP_CDN_ARCHIVOS
const dominio = process.env.VUE_APP_ENI_DOMAIN
const url_gema = process.env.VUE_APP_GEMA

const esMovil = computed(() => Boolean(window.innerWidth < 768))

function calHeight() {
  const innerheight = window.innerHeight / 100
  document
    .querySelector(':root')
    .style.setProperty('--vh', `${innerheight.toString()}px`)
}

calHeight()

onMounted(() => {
  window.addEventListener('resize', calHeight)
})

onBeforeUnmount(() => {
  window.addEventListener('resize', calHeight)
})
</script>

<template>
  <main id="principal">
    <div class="contenedor-portada">
      <video
        class="portada"
        autoplay
        muted
        loop
        playsinline
      >
        <source
          :src="`${cdn_archivos_dominio}/portada/Toxicologia-${
            esMovil ? 'movil' : 'escritorio'
          }.webm`"
          type="video/webm"
        />
      </video>

      <div class="degradado-negro-abajo">
        <div class="contenido-abajo">
          <div class="contenedor ancho-fijo">
            <h1 class="titulo-eni texto-centrado texto-color-1">
              Agentes Tóxicos y Procesos Contaminantes
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="contenedor ancho-lectura m-y-10">
      <h2 class="texto-centrado m-y-8-esc m-y-4-mov m-x-auto">
        Ecosistema Nacional Informático de Agentes Tóxicos y Procesos
        Contaminantes
      </h2>
      <p class="texto-alto m-y-8-esc m-y-4-mov">
        El Ecosistema Nacional Informático de Agentes Tóxicos y Procesos
        Contaminantes ofrece un espacio de acceso abierto para el análisis de
        datos y visualización de información generada en el marco del Programa
        Nacional Estratégico de Agentes Tóxicos y Procesos Contaminantes.
      </p>
    </div>

    <div class="contenedor ancho-fijo m-y-10">
      <div
        class="flex flex-contenido-separado contenedor ancho-fijo flex-columnas-100-al-simplificar"
      >
        <div class="columna-16 m-b-3">
          <a
            class="tarjeta-hipervinculo-externo tarjeta-eni"
            :href="`${dominio}/resa-atoyac/`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture class="fondo-imagen">
              <img :src="`${cdn_archivos_dominio}/tarjetas/atoyac.toxi.png`" />
            </picture>
            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Región de Emergencia Sanitaria y Ambiental: Cuenca del Alto
                  Atoyac
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="columna-16 m-b-3">
          <a
            class="tarjeta-hipervinculo-externo"
            :href="`${url_gema}/visualizador?seccion=agentes-toxicos-y-procesos-contaminantes`"
            target="_blank"
          >
            <picture class="fondo-imagen">
              <img
                :src="`${cdn_archivos_dominio}/tarjetas/eni-agentestoxicos-gema.png`"
              />
            </picture>
            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Explora capas del tema agentes tóxicos y procesos
                  contaminantes
                </p>
                <hr />
                <p class="subtitulo-capitulo texto-color-1">
                  Gestor de mapas (Gema)
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss">
@media (max-width: 438px) {
  .contenedor-portada {
    .contenido-abajo {
      padding: 0 !important;
      .contenedor.ancho-fijo {
        padding: 0;
        h1 {
          font-size: 2.5rem;
        }
      }
    }
  }
}
.tarjeta-hipervinculo-externo {
  min-height: 320px;
  .fondo-imagen {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
